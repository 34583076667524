import Vue from "vue";
import Vuex from "vuex";
import httpClient from "../helpers/httpClient";
Vue.use(Vuex);

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
export default new Vuex.Store({
  state: {
    token: null,
    currentStation: null,
    allStationList: [],
    getStationCommentList: null,
    currentListStationGroups: [],
    currentStationComment: [],
    otherStationList: [],
    activeScreen: 1,
    popularStationAtCity: [],
    popularStationAtCountry: [],
    listeners: [],
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    allStationListCommit(state, payload) {
      state.allStationList = payload;
    },
    currentListStationGroups(state, payload) {
      state.currentListStationGroups = payload;
    },
    setCurrentStation(state, payload) {
      state.currentStation = payload;
    },
    setCurrentStationComment(state, payload) {
      state.currentStationComment = payload;
    },
    setOtherStationList(state, payload) {
      state.otherStationList = payload;
    },
    setActiveScreen(state, payload) {
      state.activeScreen = payload;
    },
    setPopularStationAtCity(state, payload) {
      state.popularStationAtCity = payload;
    },
    setPopularStationAtCountry(state, payload) {
      state.popularStationAtCountry = payload;
    },
    setListeners(state, payload) {
      state.listeners = payload;
    },
  },
  actions: {
    async loginWithFacebook(context, payload) {
      var res = await httpClient.post("/Login/SignInWithFacebook", { AccessToken: payload});
      if (!res.data.HasError) {
        context.commit("setToken", res.data.Data.Token);
        localStorage.setItem("token", res.data.Data.Token);
      }
    },
    async getAllStatonList(context) {
      var res = await httpClient.get("/Station/ListLocations");
      context.commit("allStationListCommit", res.data.Data.markers);
    },
    async getListStationGroups(context, payload = {}) {
      var res = await httpClient.post("/Station/ListStationGroups", payload);
      context.commit("currentListStationGroups", res.data.Data.StationGroups[0]);
      var randomInt = getRandomInt(0, res.data.Data.StationGroups[0].Stations.length);
      console.log(randomInt, "random");
      context.commit("setCurrentStation", res.data.Data.StationGroups[0].Stations[randomInt]);
      // this.getPopularStationAtCity(res.data.Data.StationGroups[0].Stations[randomInt].City);
      // this.getPopularStationAtCountry(res.data.Data.StationGroups[0].Stations[randomInt].Country);
      context.dispatch("getPopularStationAtCity", res.data.Data.StationGroups[0].Stations[randomInt].City);
      context.dispatch("getPopularStationAtCountry", res.data.Data.StationGroups[0].Stations[randomInt].Country);
    },
    async getCurrentStationComments(context, payload = {}) {
      var res = await httpClient.post("/Station/ListStationComments", payload);
      if (!res.data.HasError) {
        context.commit("setCurrentStationComment", res.data.Data.Items);
      }
    },
    async getPopularStationAtCity(context, payload) {
      var res = await httpClient.post("/Station/SearchStation", { SearchTerm: null, Country: null, City: payload });
      if (!res.data.HasError) {
        context.commit("setPopularStationAtCity", res.data.Data.Items);
      }
    },
    async getPopularStationAtCountry(context, payload) {
      var res = await httpClient.post("/Station/SearchStation", { SearchTerm: null, Country: payload, City: null });
      if (!res.data.HasError) {
        context.commit("setPopularStationAtCountry", res.data.Data.Items);
      }
    },
    async getOtherStationList(context) {
      var currentStation = this.getters.getCurrentStation;
      var currentStationsGroups = this.getters.getCurrentStationGroups;
      var currentStationsGroupsCopy = [];
      if (Array.isArray(currentStationsGroups.Stations)) {
        currentStationsGroupsCopy = [...currentStationsGroups.Stations];
      }

      var otherStations = [];
      // if (currentStationsGroups.Stations) var otherStations = currentStationsGroups.Stations.sort(() => 0.5 - Math.random()).slice(0, 3);
      console.log(
        currentStation,
        "currentStation",
        currentStationsGroupsCopy.findIndex((item) => item.ID == currentStation.ID),
      );
      if (currentStationsGroups.Stations) {
        var currentStationIndex = currentStationsGroupsCopy.findIndex((item) => item.ID == currentStation.ID);
        if (currentStationIndex > -1) {
          currentStationsGroupsCopy.splice(currentStationIndex, 1);
        }
        otherStations = currentStationsGroupsCopy.sort(() => 0.5 - Math.random()).slice(0, 3);
      }

      console.log(otherStations, "otherStations");
      context.commit("setOtherStationList", otherStations);
    },
  },
  getters: {
    getToken: (state) => state.token,
    getAllStation: (state) => state.allStationList,
    getCurrentStationGroups: (state) => state.currentListStationGroups,
    getCurrentStation: (state) => state.currentStation,
    getCurrentStationComment: (state) => state.currentStationComment,
    getOtherStationList: (state) => state.otherStationList,
    getActiveScreen: (state) => state.activeScreen,
    getPopularStationAtCity: (state) => state.popularStationAtCity,
    getPopularStationAtCountry: (state) => state.popularStationAtCountry,
    getListeners: (state) => state.listeners,
  },
});
