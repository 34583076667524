<template>
  <div class="player-station-details" v-if="currentStation">
    <div class="d-flex flex-row justify-content-space-around p-1">
      <div class="p-2">
        <img src="/img/icons/frequency.svg" alt="" />
      </div>
      <div class="p-2 radioName" v-if="currentStation != null">{{ currentStation.Title != null ? currentStation.Title : "Sodio" }}</div>
      <div class="p-2 ml-auto">
        <div class="like" style="cursor: pointer">
          <div v-if="true" class="heart-icon"></div>
          <div v-else class="heart-icon-red"></div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-space-around p-1 align-items-center">
      <div class="p-2" :style="currentStation.Listeners.length > 0 ? 'visibility:visible' : 'visibility:hidden'">
        <!-- <div class="rounded-people-container d-flex align-items-center justify-content-center pl-2">
          <img class="item" src="/img/humans/t3.png" width="24.57px" height="24.57px" />
          <img class="item" src="/img/humans/t4.png" width="24.57px" height="24.57px" />
          <img class="item" src="/img/humans/t5.png" width="24.57px" height="24.57px" />
          <img class="item" src="/img/humans/t5.png" width="24.57px" height="24.57px" />
          <img class="item" src="/img/humans/t5.png" width="24.57px" height="24.57px" />
          <div class="item people-counter bg-dark d-flex align-items-center justify-content-center">15</div>
        </div> -->
        <div class="rounded-people-container d-flex align-items-center justify-content-center pl-2">
          <img v-if="currentStation.Listeners[0]" class="item" :src="currentStation.Listeners[0].Image" width="24.57px" height="24.57px" />
          <img v-if="currentStation.Listeners[1]" class="item" :src="currentStation.Listeners[1].Image" width="24.57px" height="24.57px" />
          <img v-if="currentStation.Listeners[2]" class="item" :src="currentStation.Listeners[2].Image" width="24.57px" height="24.57px" />
          <img v-if="currentStation.Listeners[3]" class="item" :src="currentStation.Listeners[3].Image" width="24.57px" height="24.57px" />
          <img v-if="currentStation.Listeners[4]" class="item" :src="currentStation.Listeners[4].Image" width="24.57px" height="24.57px" />
          <div v-if="currentStation.Listeners.length > 5" class="item people-counter bg-dark d-flex align-items-center justify-content-center">{{ currentStation.Listeners.length - 5 }}</div>
        </div>
      </div>
      <div class="p-2 station-count" v-if="currentStationGroups.Stations">
        {{ currentStationGroups.City }}, {{ currentStationGroups.Country }}<br />
        {{ currentStationGroups.Stations.length > 0 ? currentStationGroups.Stations.length : "0" }} istasyon daha var
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: ['currentStation', 'currentStationGroups'],

  data() {
    return {};
  },
  computed: {
    currentStation() {
      return this.$store.getters["getCurrentStation"];
    },
    currentStationGroups() {
      return this.$store.getters["getCurrentStationGroups"];
    },
  },
};
</script>

<style></style>
