<template>
  <div>
    <div class="chat-box px-2 py-2" v-if="comments.length > 0 || true">
      <div class="d-flex align-items-center user-comment p-1" v-for="i in comments" :key="i.ID">
        <img :src="i.Owner.Image" width="26px" height="26px" class="rounded-circle" alt="" />
        <div class="px-2 user">{{ i.Owner.Title }}</div>
        <div class="px-2 comment">{{ i.Title }}</div>
      </div>
    </div>
    <div class="chat-input d-flex align-items-center p-2">
      <div class="user-lock rounded-circle d-flex align-items-center justify-content-center p-2">
        <i class="fas fa-user-lock"></i>
      </div>
      <div class="p-2 c-pointer" @click="goLogin">Yazmak için giriş yapınız</div>
    </div>
  </div>
</template>

<script>
export default {
  // props: ["currentStation"],
  data() {
    return {
      comments: [],
    };
  },
  computed: {
    currentStation() {
      return this.$store.state.currentStation;
    },
  },
  async created() {
    await this.$store.dispatch("getCurrentStationComments");
  },
  async mounted() {
    this.comments = await this.$store.getters["getCurrentStationComment"];
  },
  methods: {
    goLogin() {
      this.$emit("goLogin");
    },
  },
  watch: {
    "currentStation.ID": {
      deep: true,
      async handler() {
        await this.$store.dispatch("getCurrentStationComments");
        this.comments = await this.$store.getters["getCurrentStationComment"];
        console.log("commentleri çektim");
      },
    },
  },
};
</script>

<style></style>
