<template>
  <div v-if="currentStation">
    <audio ref="audiofile" id="audioFiles" :src="currentStation.StreamUrl" preload="auto" style="display: none"></audio>

    <div v-if="$store.state.activeScreen == 1" :key="$store.state.activeScreen" class="player-container d-flex align-items-center justify-content-between p-4 text-white">
      <div class="search-button" @click="setActiveScreen(3)"><i class="fas fa-search"></i></div>
      <div class="player-arrow d-flex align-items-center justify-content-center" @click="goBackStation">
        <i class="fas fa-backward"></i>
      </div>
      <div class="player-play d-flex align-items-center justify-content-center" style="cursor: pointer" @click="startStop" :key="buttonControl">
        <i v-if="!buttonControl" class="fas fa-play fa-2x play-button"></i>
        <i v-else class="fas fa-pause fa-2x play-button"></i>
      </div>
      <div class="player-arrow d-flex align-items-center justify-content-center" @click="goNextStation">
        <i class="fas fa-forward"></i>
      </div>
      <div class="position-relative near-radios" @click="setActiveScreen(2)">
        <img src="/img/icons/station.svg" alt="" />
        <div class="station-badge rounded-circle position-absolute d-flex align-items-center justify-content-center" v-if="currentStationGroups.Stations">{{ currentStationGroups.Stations.length }}</div>
      </div>
    </div>
    <!-- details start  -->

    <div v-if="$store.state.activeScreen == 2" :key="$store.state.activeScreen" id="station-details" class="player-container d-flex flex-column align-items-center justify-content-between p-4 text-white">
      <div class="top-section d-flex justify-content-between align-items-center w-100">
        <div class="back-arrow" @click="setActiveScreen(1)">
          <i class="fa-solid fa-arrow-left"></i>
        </div>
        <div class="head-text">İstasyon Bilgileri</div>
        <div class="plus"></div>
      </div>
      <div class="divider-light"></div>
      <div class="details-card station-infos d-flex flex-column align-items-center justify-content-between w-100 sticky-card">
        <div class="d-flex w-100">
          <div class="col-8 flex-column pl-0">
            <div class="station-name station-count">{{ currentStation.Title }}</div>
            <div class="station-location station-count" v-if="currentStationGroups.Stations">{{ currentStationGroups.City }}, {{ currentStationGroups.Country }}</div>
            <div class="listeners" @click="setListeners(currentStation.Listeners)" v-if="currentStation.Listeners.length > 0">
              <div class="d-flex flex-row justify-content-space-around align-items-center">
                <div class="rounded-people-container d-flex align-items-center justify-content-center">
                  <img v-if="currentStation.Listeners[0]" class="item" :src="currentStation.Listeners[0].Image" width="24.57px" height="24.57px" />
                  <img v-if="currentStation.Listeners[1]" class="item" :src="currentStation.Listeners[1].Image" width="24.57px" height="24.57px" />
                  <img v-if="currentStation.Listeners[2]" class="item" :src="currentStation.Listeners[2].Image" width="24.57px" height="24.57px" />
                  <img v-if="currentStation.Listeners[3]" class="item" :src="currentStation.Listeners[3].Image" width="24.57px" height="24.57px" />
                  <img v-if="currentStation.Listeners[4]" class="item" :src="currentStation.Listeners[4].Image" width="24.57px" height="24.57px" />
                  <div v-if="currentStation.Listeners.length > 5" class="item people-counter bg-dark d-flex align-items-center justify-content-center">{{ currentStation.Listeners.length - 5 }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 p-0 d-flex justify-content-between align-items-start">
            <div class="" :key="buttonControl" @click="startStop" style="cursor: pointer">
              <div v-if="buttonControl" class="pause-icon play-button"></div>
              <div v-else class="play-icon play-button"></div>
            </div>

            <div class="like">
              <div v-if="buttonControl" class="heart-icon"></div>
              <div v-else class="heart-icon-red"></div>
            </div>
          </div>
        </div>
        <div class="divider-dark"></div>
        <div class="share">
          <div>İstasyonu paylaş</div>
          <i class="fa-sharp fa-solid fa-angle-right"></i>
        </div>
        <div class="share col-12">
          <div>Web sitesini ziyaret et</div>
          <i class="fa-sharp fa-solid fa-angle-right"></i>
        </div>
      </div>
      <div class="text-start mid-text-top mt-3">Diğer istasyonlar</div>
      <div class="mid-text-bot">Aynı bölge üzerinde bulunan diğer istasyonlara göz atabilirsiniz.</div>

      <div v-for="otherStation in currentStationGroups.Stations" :key="otherStation.ID" class="details-card d-flex flex-column align-items-center justify-content-between text-white">
        <div class="d-flex w-100">
          <div class="col-8 flex-column pl-0">
            <div class="station-name station-count">{{ otherStation.Title }}</div>
            <div class="station-location station-count">{{ otherStation.City }}, {{ otherStation.Country }}</div>
            <div class="listeners" @click="setListeners(otherStation.Listeners)" v-if="otherStation.Listeners.length > 0">
              <div class="d-flex flex-row justify-content-space-around align-items-center">
                <div class="rounded-people-container d-flex align-items-center justify-content-center">
                  <img v-if="otherStation.Listeners[0]" class="item" :src="otherStation.Listeners[0].Image" width="24.57px" height="24.57px" />
                  <img v-if="otherStation.Listeners[1]" class="item" :src="otherStation.Listeners[1].Image" width="24.57px" height="24.57px" />
                  <img v-if="otherStation.Listeners[2]" class="item" :src="otherStation.Listeners[2].Image" width="24.57px" height="24.57px" />
                  <img v-if="otherStation.Listeners[3]" class="item" :src="otherStation.Listeners[3].Image" width="24.57px" height="24.57px" />
                  <img v-if="otherStation.Listeners[4]" class="item" :src="otherStation.Listeners[4].Image" width="24.57px" height="24.57px" />
                  <div v-if="otherStation.Listeners.length > 5" class="item people-counter bg-dark d-flex align-items-center justify-content-center">{{ otherStation.Listeners.length - 5 }}</div>
                  <div class="ml-2" v-if="otherStation.Listeners.length > 0">dinliyor...</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4 p-0 d-flex justify-content-between align-items-start">
            <div @click="openStation(otherStation)" style="cursor: pointer" :key="currentStation + '-' + buttonControl">
              <div v-if="otherStation.ID == currentStation.ID && buttonControl" class="pause-icon play-button"></div>
              <div v-else class="play-icon play-button"></div>
            </div>

            <div class="like">
              <div v-if="buttonControl" class="heart-icon"></div>
              <div v-else class="heart-icon-red"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- details end  -->

    <!-- search start  -->
    <div v-if="$store.state.activeScreen == 3" :key="$store.state.activeScreen" id="station-details" class="player-container d-flex flex-column align-items-center justify-content-between p-4 text-white">
      <div class="top-section d-flex justify-content-between align-items-center w-100">
        <div class="back-arrow" @click="setActiveScreen(1)">
          <i class="fa-solid fa-arrow-left"></i>
        </div>
        <div class="head-text">İstasyon Ara</div>
        <div class="plus"></div>
      </div>
      <div class="divider-light"></div>
      <div class="d-flex flex-column gap-8px sticky-card-search">
        <div class="text-start mid-text-top">Arama</div>
        <div class="mid-text-bot">Sodioya kayıtlı dünya üzerindeki {{ allStationList.length }} istasyon içerisinden arayın.</div>
        <el-input placeholder="İlgi alanlarında ara..." v-model="FilterSearch" class="search input-with-select" clearable @clear="clearFilter">
          <el-button @click="searchStationFilter" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="d-flex flex-column gap-8px" v-if="filteredStationList.length > 0" :key="filteredStationList.length">
        <div class="text-start mid-text-top">Arama Sonucu</div>
        <div class="mid-text-bot">Aramanıza göre alakalı {{ filteredStationList.length }} radyo sonucu gösterilmektedir.</div>
        <div v-for="otherStation in filteredStationList" :key="otherStation.ID" class="details-card d-flex flex-column align-items-center justify-content-between text-white">
          <div class="d-flex w-100">
            <div class="col-8 flex-column pl-0">
              <div class="station-name station-count">{{ otherStation.Title }}</div>
              <div class="station-location station-count">{{ otherStation.City }}, {{ otherStation.Country }}</div>
              <div class="listeners" @click="setListeners(otherStation.Listeners)" v-if="otherStation.Listeners.length > 0">
                <div class="d-flex flex-row justify-content-space-around align-items-center">
                  <div class="rounded-people-container d-flex align-items-center justify-content-center">
                    <img v-if="otherStation.Listeners[0]" class="item" :src="otherStation.Listeners[0].Image" width="24.57px" height="24.57px" />
                    <img v-if="otherStation.Listeners[1]" class="item" :src="otherStation.Listeners[1].Image" width="24.57px" height="24.57px" />
                    <img v-if="otherStation.Listeners[2]" class="item" :src="otherStation.Listeners[2].Image" width="24.57px" height="24.57px" />
                    <img v-if="otherStation.Listeners[3]" class="item" :src="otherStation.Listeners[3].Image" width="24.57px" height="24.57px" />
                    <img v-if="otherStation.Listeners[4]" class="item" :src="otherStation.Listeners[4].Image" width="24.57px" height="24.57px" />
                    <div v-if="otherStation.Listeners.length > 5" class="item people-counter bg-dark d-flex align-items-center justify-content-center">{{ otherStation.Listeners.length - 5 }}</div>
                    <div class="ml-2" v-if="otherStation.Listeners.length > 0">dinliyor...</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4 p-0 d-flex justify-content-between align-items-start">
              <div @click="openStation(otherStation)" style="cursor: pointer" :key="currentStation + '-' + buttonControl">
                <div v-if="otherStation.ID == currentStation.ID && buttonControl" class="pause-icon play-button"></div>
                <div v-else class="play-icon play-button"></div>
              </div>

              <div class="like">
                <div v-if="buttonControl" class="heart-icon"></div>
                <div v-else class="heart-icon-red"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="d-flex flex-column gap-8px">
          <div class="text-start mid-text-top">{{ currentStation.City }} İçin Popüler</div>
          <div class="mid-text-bot">{{ currentStation.City }} konumunda popüler {{ populerCityStationList.length }} radyo</div>
          <div v-for="otherStation in populerCityStationList" :key="otherStation.ID" class="details-card d-flex flex-column align-items-center justify-content-between text-white">
            <div class="d-flex w-100">
              <div class="col-8 flex-column pl-0">
                <div class="station-name station-count">{{ otherStation.Title }}</div>
                <div class="station-location station-count">{{ otherStation.City }}, {{ otherStation.Country }}</div>
                <div class="listeners" @click="setListeners(otherStation.Listeners)" v-if="otherStation.Listeners.length > 0">
                  <div class="d-flex flex-row justify-content-space-around align-items-center">
                    <div class="rounded-people-container d-flex align-items-center justify-content-center">
                      <img v-if="otherStation.Listeners[0]" class="item" :src="otherStation.Listeners[0].Image" width="24.57px" height="24.57px" />
                      <img v-if="otherStation.Listeners[1]" class="item" :src="otherStation.Listeners[1].Image" width="24.57px" height="24.57px" />
                      <img v-if="otherStation.Listeners[2]" class="item" :src="otherStation.Listeners[2].Image" width="24.57px" height="24.57px" />
                      <img v-if="otherStation.Listeners[3]" class="item" :src="otherStation.Listeners[3].Image" width="24.57px" height="24.57px" />
                      <img v-if="otherStation.Listeners[4]" class="item" :src="otherStation.Listeners[4].Image" width="24.57px" height="24.57px" />
                      <div v-if="otherStation.Listeners.length > 5" class="item people-counter bg-dark d-flex align-items-center justify-content-center">{{ otherStation.Listeners.length - 5 }}</div>
                      <div class="ml-2" v-if="otherStation.Listeners.length > 0">dinliyor...</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 p-0 d-flex justify-content-between align-items-start">
                <div @click="openStation(otherStation)" style="cursor: pointer" :key="currentStation + '-' + buttonControl">
                  <div v-if="otherStation.ID == currentStation.ID && buttonControl" class="pause-icon play-button"></div>
                  <div v-else class="play-icon play-button"></div>
                </div>

                <div class="like">
                  <div v-if="buttonControl" class="heart-icon"></div>
                  <div v-else class="heart-icon-red"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column gap-8px">
          <div class="text-start mid-text-top">{{ currentStation.Country }} İçin Popüler</div>
          <div class="mid-text-bot">{{ currentStation.Country }} konumunda popüler {{ populerCountryStationList.length }} radyo</div>
          <div v-for="otherStation in populerCountryStationList" :key="otherStation.ID" class="details-card d-flex flex-column align-items-center justify-content-between text-white">
            <div class="d-flex w-100">
              <div class="col-8 flex-column pl-0">
                <div class="station-name station-count">{{ otherStation.Title }}</div>
                <div class="station-location station-count">{{ otherStation.City }}, {{ otherStation.Country }}</div>
                <div class="listeners" @click="setListeners(otherStation.Listeners)" v-if="otherStation.Listeners.length > 0">
                  <div class="d-flex flex-row justify-content-space-around align-items-center">
                    <div class="rounded-people-container d-flex align-items-center justify-content-center">
                      <img v-if="otherStation.Listeners[0]" class="item" :src="otherStation.Listeners[0].Image" width="24.57px" height="24.57px" />
                      <img v-if="otherStation.Listeners[1]" class="item" :src="otherStation.Listeners[1].Image" width="24.57px" height="24.57px" />
                      <img v-if="otherStation.Listeners[2]" class="item" :src="otherStation.Listeners[2].Image" width="24.57px" height="24.57px" />
                      <img v-if="otherStation.Listeners[3]" class="item" :src="otherStation.Listeners[3].Image" width="24.57px" height="24.57px" />
                      <img v-if="otherStation.Listeners[4]" class="item" :src="otherStation.Listeners[4].Image" width="24.57px" height="24.57px" />
                      <div v-if="otherStation.Listeners.length > 5" class="item people-counter bg-dark d-flex align-items-center justify-content-center">{{ otherStation.Listeners.length - 5 }}</div>
                      <div class="ml-2" v-if="otherStation.Listeners.length > 0">dinliyor...</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 p-0 d-flex justify-content-between align-items-start">
                <div @click="openStation(otherStation)" style="cursor: pointer" :key="currentStation + '-' + buttonControl">
                  <div v-if="otherStation.ID == currentStation.ID && buttonControl" class="pause-icon play-button"></div>
                  <div v-else class="play-icon play-button"></div>
                </div>

                <div class="like">
                  <div v-if="buttonControl" class="heart-icon"></div>
                  <div v-else class="heart-icon-red"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- search end -->

    <!-- dinleyiciler detaylı görünüm -->

    <div v-if="$store.state.activeScreen == 4" :key="$store.state.activeScreen" id="station-details" class="player-container d-flex flex-column align-items-center justify-content-between p-4 text-white">
      <div class="top-section d-flex justify-content-between align-items-center w-100">
        <div class="back-arrow" @click="backToListenersMenu">
          <i class="fa-solid fa-arrow-left"></i>
        </div>
        <div class="head-text">Dinleyiciler</div>
        <div class="plus"></div>
      </div>
      <div class="divider-light"></div>
      <div class="details-card" v-if="$store.state.listeners.length > 0">
        <div class="row">
          <div class="col-md-6 mb-4" v-if="$store.state.listeners[0].Image">
            <div class="listener-card" :style="{ backgroundImage: 'url(' + $store.state.listeners[0].Image + ')' }">
              <div class="card-text">
                <div class="name">{{ "Ahmet || Sakarya" }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4" v-if="$store.state.listeners[1]">
            <div class="listener-card" :style="{ backgroundImage: 'url(' + $store.state.listeners[1].Image + ')' }">
              <div class="card-text">
                <div class="name">{{ "Ahmet || Sakarya" }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4" v-if="$store.state.listeners[2]">
            <div class="listener-card" :style="{ backgroundImage: 'url(' + $store.state.listeners[2].Image + ')' }">
              <div class="card-text">
                <div class="name">{{ "Ahmet || Sakarya" }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4" v-if="$store.state.listeners[3]">
            <div class="listener-card" :style="{ backgroundImage: 'url(' + $store.state.listeners[3].Image + ')' }">
              <div class="card-text">
                <div class="name">{{ "Ahmet || Sakarya" }}</div>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center align-items-center" v-if="$store.state.listeners.length > 2">
            <div class="col-8 anon-plus">+{{ $store.state.listeners.length - 2 }} anonim dinleyici</div>
          </div>
        </div>
      </div>
    </div>

    <!-- dinleyiciler detaylı görünüm -->
  </div>
</template>

<script>
import httpClient from "../helpers/httpClient";

export default {
  components: {},
  data() {
    return {
      audio: null,
      playing: false,
      buttonControl: true,
      loaded: false,
      FilterSearch: null,
      filteredStationList: [],
      backScreen: 1,
    };
  },
  computed: {
    currentStation() {
      return this.$store.getters["getCurrentStation"];
    },
    currentStationGroups() {
      return this.$store.getters["getCurrentStationGroups"];
    },
    otherStations() {
      return this.$store.getters["getOtherStationList"];
    },
    allStationList() {
      return this.$store.getters["getAllStation"];
    },
    populerCityStationList() {
      return this.$store.getters["getPopularStationAtCity"];
    },
    populerCountryStationList() {
      return this.$store.getters["getPopularStationAtCountry"];
    },
    listerners() {
      return this.$store.getters["getListeners"];
    },
  },

  mounted() {
    if (this.currentStation) {
      this.audio = document.getElementById("audioFiles");
      this.audio.volume = 1;
      this.audio.addEventListener("loadeddata", this.load);
      this.audio.autoplay = true;
      this.playing = true;
    }
  },
  methods: {
    async searchStationFilter() {
      var res = await httpClient.post("/Station/SearchStation", { SearchTerm: this.FilterSearch, Country: null, City: null });
      if (!res.data.HasError) {
        this.filteredStationList = res.data.Data.Items;
      }
    },
    clearFilter() {
      this.FilterSearch = null;
      this.filteredStationList = [];
    },
    setActiveScreen(screen) {
      this.$store.commit("setActiveScreen", screen);
    },
    setListeners(listerners) {
      this.$store.commit("setListeners", listerners);
      this.backScreen = this.$store.state.activeScreen;
      this.setActiveScreen(4);
    },
    backToListenersMenu() {
      this.setActiveScreen(this.backScreen);
    },
    startStop() {
      this.buttonControl = !this.buttonControl;
      this.playing = !this.playing;
      if (this.playing) {
        console.log("çalıyor");
      } else {
        console.log("durduruldu");
      }
    },
    goBackStation() {
      this.$emit("goBackStation");
    },
    goNextStation() {
      this.$emit("goNextStation");
    },
    async openStation(station) {
      if (this.currentStation.ID == station.ID) {
        console.log("idler aynı start stop yapçam");
        this.startStop();
      } else {
        console.log("idler aynı değil yeni kanala geççem");
        await this.$store.commit("setCurrentStation", station);
      }
    },
    load() {
      if (this.audio.readyState >= 2) {
        this.loaded = true;
        return (this.playing = true);
      }
    },
  },
  watch: {
    async playing(value) {
      if (value) {
        return this.audio.play();
      }
      this.audio.pause();
    },
    "currentStation.StreamUrl": {
      async handler() {
        this.buttonControl = true;

        var payload = { StationId: `${this.currentStation.ID}`, Page: 1, PageSize: 8 };
        await this.$store.dispatch("getCurrentStationComments", payload);
        if (this.audio == null) {
          this.audio = document.getElementById("audioFiles");
          this.audio.volume = 1;
          this.audio.addEventListener("loadeddata", this.load);
          this.audio.autoplay = true;
          this.playing = true;
        }
      },
    },
  },
};
</script>

<style></style>
