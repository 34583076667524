<template>
  <div class="mapbox">
    <div id="map" class="container-fluid bg-dark p-0" ref="map">
      <div class="head-bg">
        <current-station></current-station>
        <player v-if="currentStation" @goBackStation="goBackStation" @goNextStation="goNextStation"></player>
        <chat-box v-if="$store.state.activeScreen == 1" class="mt-auto" @goLogin="goLogin"></chat-box>
      </div>
      <div v-if="mapDragControl" class="circle-center"></div>
      <div v-else class="sodio_map_center"></div>

      <el-dialog custom-class="login-dialog" width="390" :visible.sync="loginDialog" :close-on-click-modal="false" :show-close="false">
        <!-- :close-on-press-escape="false" -->
        <div class="dialog-buttons">
          <div class="dialog-title">Lütfen devam etmek için oturum açınız</div>

          <div class="google-button" type="primary" icon="el-icon-google" size="medium">
            <div class="google-icon"></div>
            Google ile oturum açın
          </div>
          <div class="facebook-button ml-0" type="primary" icon="el-icon-facebook" size="medium" @click="loginWithFacebook">
            <div class="facebook-icon"></div>
            Facebook ile giriş yap
          </div>
          <!-- <facebook-login class="facebook-button" :app-id="appId" :callback="onFacebookLogin">
            <div class="facebook-button ml-0" type="primary" icon="el-icon-facebook" size="medium">
              <div class="facebook-icon"></div>
              Facebook ile giriş yap
            </div>
          </facebook-login> -->
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>

<script>
import mapBox from "mapbox-gl";
import CurrentStation from "./CurrentStation.vue";
import Player from "./Player.vue";
import ChatBox from "./ChatBox.vue";
import sortByDistance from "sort-by-distance";
import FacebookLogin from "vue-facebook-login-component";
// window.fbAsyncInit = function() {
//     FB.init({
//       appId      : '576975160113769',
//       xfbml      : true,
//       version    : 'v16.0'
//     });
//     FB.AppEvents.logPageView();
//   };

//   (function(d, s, id){
//      var js, fjs = d.getElementsByTagName(s)[0];
//      if (d.getElementById(id)) {return;}
//      js = d.createElement(s); js.id = id;
//      js.src = "https://connect.facebook.net/en_US/sdk.js";
//      fjs.parentNode.insertBefore(js, fjs);
//    }(document, 'script', 'facebook-jssdk'));
// import httpClient from "../helpers/httpClient";

export default {
  name: "Map",
  components: {
    CurrentStation,
    Player,
    ChatBox,
    FacebookLogin,
  },
  data() {
    return {
      map: "",
      features: [],
      markers: [],
      mapDragControl: false,
      currentStationGroups: {},
      sortByDistanceStations: [],
      loginDialog: false,
      appId: "576975160113769",
    };
  },
  computed: {
    allStationList() {
      return this.$store.getters["getAllStation"];
    },
    currentStation: {
      get() {
        return this.$store.state.currentStation;
      },
      set(value) {
        this.$store.commit("setCurrentStation", value);
      },
    },
  },
  methods: {
    loginWithFacebook() {
      const bu = this;
      FB.login(
        function (response) {
          console.log("response", response);
          if (response.authResponse) {
            console.log("Welcome!  Fetching your information.... ", response.authResponse);
            var accessToken = response.authResponse.accessToken;
            console.log("başardık", accessToken);
            bu.$store.dispatch("loginWithFacebook", accessToken);
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "public_profile,email" },
      );
    },
    async goBackStation() {
      console.log("goBackStation");
      let index = this.currentStationGroups.Stations.findIndex((item) => item.ID == this.currentStation.ID);
      console.log(index);
      if (index == 0) {
        this.$store.commit("setCurrentStation", this.currentStationGroups.Stations[this.currentStationGroups.Stations.length - 1]);
      } else {
        this.$store.commit("setCurrentStation", this.currentStationGroups.Stations[index - 1]);
        console.log(index - 1 + ".sıradakini actım");
      }
      await this.$store.dispatch("getOtherStationList");
    },
    async goNextStation() {
      console.log("goNextStation");
      let index = this.currentStationGroups.Stations.findIndex((item) => item.ID == this.currentStation.ID);
      console.log(index);
      if (index == this.currentStationGroups.Stations.length - 1) {
        this.$store.commit("setCurrentStation", this.currentStationGroups.Stations[0]);
      } else {
        this.$store.commit("setCurrentStation", this.currentStationGroups.Stations[index + 1]);
        console.log(index + 1 + ".sıradakini actım");
      }
      await this.$store.dispatch("getOtherStationList");
    },

    async goLogin() {
      this.loginDialog = true;
    },

    async flyToNear() {
      var centerPosition = this.map.getCenter();
      console.log(centerPosition.lng);
      console.log(centerPosition.lat);
      let origin = { longitude: centerPosition.lng, latitude: centerPosition.lat };
      let opts = {
        yName: "latitude",
        xName: "longitude",
      };
      this.sortByDistanceStations = sortByDistance(origin, this.markers, opts);
      console.log(this.sortByDistanceStations[0]);
      console.log(this.sortByDistanceStations);
      this.map.flyTo({
        center: [this.sortByDistanceStations[0].longitude, this.sortByDistanceStations[0].latitude],
        essential: true,
      });
      let payload = {
        Longitude: this.sortByDistanceStations[0].longitude,
        Latitude: this.sortByDistanceStations[0].latitude,
        MaxDistance: 20000,
        Limit: 1,
      };
      await this.$store.dispatch("getListStationGroups", payload);
      this.currentStation = this.$store.getters["getCurrentStation"];
      this.currentStationGroups = this.$store.getters["getCurrentStationGroups"];
      console.log("getOtherStationList");
      await this.$store.dispatch("getOtherStationList");
    },
    loadMarker() {
      for (var marker of this.allStationList) {
        marker.pop();
        var s = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [marker[1], marker[0]],
          },
        };
        var g = {
          longitude: marker[1],
          latitude: marker[0],
        };
        this.features.push(s);
        this.markers.push(g);
      }
    },
  },
  async created() {
    await this.$store.dispatch("getAllStatonList");
    await this.$store.dispatch("getOtherStationList");
  },
  mounted() {
    mapBox.accessToken = "pk.eyJ1IjoiaW50ZXJuYXRpdmUiLCJhIjoiY2t2N3R1NDR6MTh5NDJxb2syYzdybDRvdiJ9.e-BD2lke37fUbUFv2KMskA";
    this.map = new mapBox.Map({
      container: "map", // container ID
      style: "mapbox://styles/internative/ckv7wc0jh0gsr14ophiihuw5q", // style URL
      center: [28.9, 40.9], // starting position [lng, lat]
      zoom: 5, // starting zoom
    });
    this.map.dragRotate.disable();
    setTimeout(() => {
      this.loadMarker();
    }, 1000);

    this.map.on("load", async () => {
      this.map.addSource("points", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: this.features,
        },
      });
      this.map.addLayer({
        id: "points",
        source: "points",
        type: "circle",
        paint: {
          "circle-color": "#4264fb",
          "circle-radius": 4,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#ffffff",
        },
      });
      this.flyToNear();
    });
    this.map.on("drag", () => {
      this.mapDragControl = true;
    });
    this.map.on("dragend", async () => {
      this.mapDragControl = false;
      this.flyToNear();
    });
    // window.fbAsyncInit = function () {
    //   FB.init({
    //     appId: "576975160113769",
    //     cookie: true,
    //     xfbml: true,
    //     version: "v10.0",
    //   });
    // };

    // (function (d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) return;
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    //   console.log("facebook");
    // })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      FB.init({
        appId: "576975160113769",
        xfbml: true,
        version: "v16.0",
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
};
</script>
